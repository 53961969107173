export default class UserEntity {
    static getEntityData(elem: any) {
        let o  = {
            uuid: elem.querySelector("#user_uuid") ? (elem.querySelector("#user_uuid") as HTMLInputElement).value : null,
            first_name: (elem.querySelector("#user_first_name") as HTMLInputElement).value,
            last_name: (elem.querySelector("#user_last_name") as HTMLInputElement).value,
            email: (elem.querySelector("#user_email") as HTMLInputElement).value,
            external: (elem.querySelector("#user_external") as HTMLInputElement).checked,
        }
        if (elem.querySelector("#user_password")) {
            //@ts-ignore
            o.password = (elem.querySelector("#user_password") as HTMLInputElement).value;
        }
        if (elem.querySelector("#user_role")) {
            //@ts-ignore
            o.roles = Array.from(elem.querySelectorAll("#user_role option:checked")).map(o => o.value);
        }
        if (elem.querySelector("#user_superior_id")) {
            //@ts-ignore
            o.superior_id = (elem.querySelector("#user_superior_id") as HTMLInputElement).value;
        }

        return o
    }
}